<template>
    <v-app>
        <v-main>
            <v-row no-gutters justify="center" align="center" class="banner-container">
                <v-col cols="1"></v-col>
                <v-col cols="10">
                    <img id="banner" src="@/assets/images/logo.jpg" style="width: 100%; height: 100%;">
                </v-col>
                <v-col cols="1"></v-col>
            </v-row>

            <v-container fluid>
                <v-card
                    elevation="2"
                    class="mx-2 my-2"
                    outlined
                    height="70vh"
                >
                    <v-card-title
                    style="word-break: break-word"
                    class="
                    text-wrap
                    mb-4
                    font-weight-medium 
                    text-body-1
                    justify-center 
                    mx-auto 
                    text-center">
                        PRIVATE INFORMATION PROVISIONS FOR LOAN APPLICATION
                    </v-card-title>


                    <v-card-text class="text-subtitle-1 text-wrap font-weight-medium text-justify">
                        <p>The loan applicant (“Borrower”) acknowledges and agrees that, in the course of his/her loan application with City Savings Bank, Inc. (“CitySavings”), and throughout the term of his/her loan if such loan application is granted, CitySavings may receive or have access to Personal Information and/or Sensitive Personal Information and/or any financial information of the Borrower which CitySavings may consider as relevant in evaluating the loan application (hereinafter collectively referred to as “Private Information.”)  Borrower expressly consents to the processing, collection, transmission, storage, disposal, use and disclosure of Private Information by CitySavings for the following purposes:</p>
                        
                        <p><strong>(a)</strong> to manage Borrower’s account(s), including endorsement to outsourced collections service provider in case of default, any consultant, adviser, auditor, counsel, agent, contractor or sub-contractor of CitySavings performing services for the purpose of assisting or rendering services to CitySavings in the administration or promotion of its lending business;</p>

                        <p><strong>(b)</strong> to confirm, update and enhance CitySavings’ records;</p>

                        <p><strong>(c)</strong> for statistical analysis, internally by CitySavings and/or any service provider it may engage for the purpose;</p>

                        <p><strong>(d)</strong> to establish any identity or otherwise as required under applicable legislation;</p>

                        <p><strong>(e)</strong> to assess Borrower’s credit status on an on-going basis;</p>

                        <p><strong>(f)</strong> for marketing and promotional of CitySavings products; or</p>

                        <p><strong>(g)</strong> any other purpose, as considered necessary or appropriate by CitySavings</p>

                        <p>In each case, the processing of Private Information may continue after the termination of the loan agreement. CitySavings may disclose the Borrower’s Private Information:</p>

                        <p><strong>(a)</strong> to a credit reference agency where it may be accessed by other financial institutions to assist assessment of any application for credit made to CitySavings and for debt tracing and fraud prevention;</p>

                        <p><strong>(b)</strong> to its parent company or any of its affiliate or subsidiary;</p>

                        <p><strong>(c)</strong> to any consultant, adviser, auditor, counsel, agent, contractor or sub-contractor of CitySavings performing services for the purpose of assisting or rendering services to CitySavings in the administration or promotion of its lending business;</p>

                        <p><strong>(d)</strong> to any person to whom CitySavings proposes to assign or transfer of its rights and/or duties to the loan;</p>

                        <p><strong>(e)</strong> to any guarantor or person providing security in relation to Borrower’s obligation;</p>

                        <p><strong>(f)</strong> as required or permitted by law, regulation, court or any regulatory authority; or</p>

                        <p><strong>(g)</strong> as otherwise considered necessary or appropriate by CitySavings.</p>

                        <p>
                            “Personal Information” as used herein means information provided to CitySavings by or at the direction of Borrower, or to which access was provided to CitySavings by or at the direction of Borrower wherein: (i) the identity of an individual is apparent or can be reasonably and directly ascertained; or (ii) when put together with other information, would directly identify an individual.
                        </p>

                        <p>
                            “Sensitive Personal Information” as used herein means any Personal Information that: (i) pertains to an individual’s race, ethnic origin, marital status, age, color, and religious, philosophical or political affiliations; (ii) pertains to an individual’s health, education, genetic or sexual life, or to any proceedings in court; (iii) is issued by government agencies peculiar to an individual, e.g., social security numbers and health records; or (iv) is specifically established by an executive order or an act of Congress to be kept classified.

                        </p>

                        <p>
                            Furthermore by agreeing to this form, the Borrower understands and expressly waives his/her right under confidentiality laws including but not limited to RA 1405 or The Law on Secrecy of Bank Deposits, RA 6426 or The Foreign Currency Deposit Act and RA 8791 or the General Banking Law and their respective implementing rules and regulations and allow processing, storing, access to or sharing of any information regarding the aforementioned Salary Loan as well as the Bank products, services and channels which the Depositor has activated, enrolled in or availed pursuant to this Form.
                        </p>
                        
                    </v-card-text>
                </v-card>
            </v-container>
        </v-main>

        <v-bottom-navigation
            grow
            height="48"
            background-color="#ffb349"
        >
            <v-btn @click="handleBack()" block depressed class="btn_bottom">
                BACK
            </v-btn>
        </v-bottom-navigation>
  </v-app>
</template>

<script>

export default {

    data: function() {
        return{
    
        }
    },
    methods:{
        handleBack(){
            this.$router.push({ path: '/attachment/index' })
        },
    },
    created() {
       
    }

}
</script>

<style scoped>
    .theme--light.v-bottom-navigation .v-btn:not(.v-btn--active){
        color: #fff !important;
    }
    .theme--light.v-bottom-navigation{
        color: #fff !important;
    }
    .btn_bottom{
        font-size: 18px !important;
        font-weight:  370 !important;
    }
    .v-card {
        display: flex !important;
        flex-direction: column;
        flex-grow: 1;
        overflow-y: scroll;
        height: calc(var(--vh, 1vh) * 65);
    }
    ::-webkit-scrollbar-track {
        border-radius: 0.125rem;
        background-color: lightgray;
    }
    ::-webkit-scrollbar {
        width: 0.25rem;
        border-radius: 0.125rem;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 0.125rem;
        background-color: gray;
    }
</style>